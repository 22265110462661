import React from 'react'

import Layout from '../components/layout'

export default function Home() {
  return (
    <Layout>
      <figure
        style={{
          maxWidth: '768px',
        }}
      >
        <div className='fluid-width-video-wrapper'>
          <iframe
            width='768'
            height='432'
            src='https://www.youtube.com/embed/f7lyewvKkLw?rel=0'
            frameBorder='0'
            allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
            title='Metal teaser for The Golden Rule'
          ></iframe>
        </div>
        <figcaption>
          Get ready! <a href="https://www.youtube.com/channel/UCgbiY3BS7JR0F3sfUSeW0vQ?sub_confirmation=1">Subscribe on&nbsp;YouTube</a>
        </figcaption>
      </figure>
    </Layout>
  )
}
